@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=PT+Mono&display=swap');

/* text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-blue-500 hover:bg-white mt-4 md:mt-0 */

/* .btn {
  @apply text-sm px-4 py-2 leading-none border rounded text-white border-white ;
}

/* .btn-blue {
  @apply bg-blue-500 text-white;
} */

/* .btn-blue:hover {
  @apply border-transparent hover:text-blue-500 hover:bg-white mt-4 md:mt-0;
} */

.alert {
  @apply italic text-pink-500;
}

/* .resume_box {
  @apply ring ring-yellow-300 border-4 border-blue-500 rounded-md max-w-full h-auto align-middle;
} */
